body, html {
  min-height: 100%;
  height: auto!important;
  height: 100%;
  font-family: 'Santander Text', sans-serif;
}

body {
  // min-height: 100vh;
  overflow: overlay;
  font-size: 16px;
  color: var(--neutral-black);
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;

  }

  &::-webkit-scrollbar-button:increment,
  .contenedor::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-red);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--grey-line);
    margin-right: 10px;
  }
}

//Max-width control
//MD size
@media only screen and (max-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

//LG size
@media only screen and (min-width: 1281px) {
  .container {
    max-width: 1280px;
  }
}

//Responsive size
@media only screen and (max-width: 992px) {
  .container {
    max-width: calc(100% - 20px);
  }
}

.scroll-styled {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar-button:increment,
  .contenedor::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-red);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--grey-line);
    margin-right: 10px;
  }
}
