//mensaje error
.msg-container {
  padding: 3em;
  border: solid 1px var(--soft-grey-line);
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  button {
    width: 224px;
    margin: 0 8px;
  }
  i {
    font-size: 80px;
  }
}

.msg-error {
  border-color: var(--primary-red);
}
