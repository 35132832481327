.form-group {
  label:not(.regulation-check) {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }

  .regulation-check {
    margin-top: 38px;
  }
}

.form-control {
  background-color: var(--input-background-form) !important;
  border: 1px solid var(--input-border-form) !important;
  border-bottom-color: var(--input-line-base-form) !important;

  &[readonly] {
    background-color: transparent;

    &.input-translate {
      background-color: var(--dark-yellow-14);
      border: none;
    }
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey-placeholder);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey-placeholder);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey-placeholder);
  }

  &:disabled {
    border-color: var(--grey-disabled);
    background-color: var(--grey-disabled);

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--neutral-black);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--neutral-black);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--neutral-black);
    }
  }

  &:focus {
    border-color: var(--blue-focus);
    box-shadow: none;
    outline: none;
    outline-color: transparent !important;
  }

  &.input-translate {
    background-color: var(--dark-yellow-14);
    border: none;
  }
}

fieldset[disabled] {
  .form-control {
    border-color: var(--grey-disabled);
    background-color: var(--grey-disabled);
    pointer-events: none;
    color: var(--neutral-black);
  }
}

.label-txt {
  font-size: 13px;
  color: var(--neutral-black);
  font-weight: 600;
  text-transform: uppercase;
}

// .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container

.combo {
  & > .ng-select-container {
    border: 1px solid var(--grey-line);
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: var(--blue-focus);
    box-shadow: none;
  }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--primary-red);
  border-style: solid;
  border-width: 0 0.1em 0.1em 0;
  width: 0.75em !important;
  height: 0.75em !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-color: var(--primary-red);
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 1 !important;
}

// Flecha abajo
.combo.ng-select-opened {
  & .ng-arrow-wrapper > .ng-arrow {
    border-color: var(--primary-red);
    border-style: solid;
    border-width: 0.1em 0 0 0.1em;
    width: 0.75em !important;
    height: 0.75em !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-sizing: border-box;
  }
}

// Oculta el icono cerrar
.ng-select .ng-arrow-wrapper,
.ng-clear {
  color: transparent;
  position: absolute;
}

.check {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
  margin-bottom: 0;

  /* Hide the browser's default checkbox */
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark:after {
      display: flex;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid var(--grey-line);
    border-radius: 2px;
    background-color: var(--neutral-white);

    &.checked {
      &:after {
        content: "✔";
        color: var(--primary-red);
        display: none;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.mandatory-field:after {
  content: '*';
  padding-left: 2px;
  color: var(--primary-red);
}

app-search-input.disabled {
  .search-input {
    .form-control {
      background-color: #e9ecef;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--neutral-black);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--neutral-black);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--neutral-black);
      }
    }

    pointer-events: none;
  }
}

.detail-view-position {
  position: absolute;
  top: 0;
  background: var(--neutral-white);
  width: 100%;
  left: 0;
  min-height: 100vh;
  z-index: 3;
  height: 100%;
  overflow: auto;

  .header {
    height: 64px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fafafa;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box-form {
    &.disableBox {
      background-color: var(--grey-disabled);
      border-color: var(--grey-disabled);
      box-shadow: none;

      .box-info {
        div[class^='col-'], div[class*=' col-'] {
          margin-bottom: .5rem;
        }
      }
    }

    i.icn-dropdown-up {
      position: absolute;
      top: 26px;
      right: 10px;
    }
  }

  .box-info {
    div[class^='col-'], div[class*=' col-'] {
      min-height: 55.33px;
    }
  }
}

.calendar-input {
  position: relative;

  i.icon-calendar-ds {
    position: absolute;
    bottom: 7px;
    right: 12px;
    font-size: 24px;
    cursor: pointer;
  }
}

.red-round-check {
  position: relative;

  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: -10;
    height: 24px;
    width: 24px;
    background-color: var(--neutral-white);
    border-radius: 50%;
    border: 1px solid;
    border-color: lightgrey;
    cursor: pointer;
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
  }

  .check-container input:checked ~ .checkmark:after {
    display: block;
  }

  .check-container .checkmark:after {
    width: 12px;
    height: 12px;
    background-color: var(--primary-red);
    border-radius: 50%;
    left: 5px;
    top: 5px;
  }
}

//Nuevo search input
.ng-select-search {
  position: relative;

  .ng-arrow-wrapper {
    z-index: 2;
  }

  .ng-arrow {
    border-color: transparent !important;
  }

  i.icn-search {
    position: absolute;
    top: 5px;
    right: 12px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
  }
}

.filter-dropdown {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid var(--primary-red);
    cursor: pointer;
    transition: all 0.3s ease-out;;

    i.icn-dropdown {
      font-size: 24px;
      transition: all 0.3s ease-out;;
    }

    &.open-filter {
      border-bottom-color: transparent;

      i.icn-dropdown {
        transform: rotate(180deg);
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      color: var(--primary-red);
    }
  }
}

.error-msg {
  color: #ec0000;
  font-size: 14px;
}
