.box-login {
  float: left;
  font-weight: bold;
  list-style: none;
  margin: 10px;
  padding: 25px 40px;
  position: relative;
  text-align: left;
  transition: box-shadow 350ms;
  @media only screen and (max-width: 798px) {
    width: calc(100% - 20px);
  }
}

.contact-container-login {
  border-radius: 4px;
  background-color: #f5f9fb;
  border: solid 1px var(--grey-line);
  padding: 1em;
  margin: 1.5rem 0 2rem 0;
  box-sizing: border-box;

  a {
    text-decoration: none;
  }
}

.login-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px;
  width: 165px;
  text-align: center;
  box-sizing: border-box;

  span {
    font-size: 48px;
  }
}

.top-absolute-line-login {
  height: 1px;
  background-color: var(--grey-line);
  position: absolute;
  width: 100%;
  left: 0;
}

.link-txt {
  color: var(--primary-red);
  cursor: pointer;
}

.separator-line {
  background-color: var(--grey-line);
  height: 1px;
  left: 0;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.validation-errors {
  color: var(--primary-red);
  font-size: 0.8rem;
}

.margin-top-access {
  margin-top: 4rem;
}

.send-new-otp {
  display: flex;
  font-size: 14px;
  justify-content: right;
  margin: 5px 0 10px;
  width: 100%;

  .send-code-txt {
    color: var(--primary-red);
    cursor: pointer;
  }

  .code-sent-txt {
    color: var(--neutral-black);
  }
}

.header-language {
  position: absolute;
  right: 3%;
  cursor: pointer;

  .header-language-item {
    i {
      &:before {
        color: var(--neutral-white) !important;
      }
    }
  }

  .header-language-item-red {
    i {
      &:before {
        color: var(--primary-red);
      }
    }
  }

  .tooltip-container {
    top: 50px;
    right: 0;
    width: auto;
    max-width: 190px;
    cursor: pointer;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    background-color: #ffffff;
    padding: 6px 20px;
    z-index: 11;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -8 px;
      top: 1px;
      right: 13px;
      box-sizing: border-box;
      border: 8px solid #d9d9d9;
      border-color: #ffffff #ffffff transparent transparent;
      transform-origin: 0 0;
      transform: rotate(
          -45deg);
      box-shadow: 1px -2px 1px 0 rgb(0 0 0 / 25%);
    }

    .icon-success::after {
      content: "✓";
      color: red;
      font-size: 16px;
      font-weight: bold;
      padding-left: 4px;
    }
  }
}

.btn-go-back {
  font-size: 1rem;
  color: var(--primary-red);
  padding: 0;
  background-color: transparent;
  border: 0;
}
