
:root {
  // Primary colors
  --primary-red: #ec0000;
  --secondary-red: #cc0000;
  --tertiary-red: #990000;

  // Neutral color
  --neutral-black: #333333;
  --secondary-black: #000000;
  --neutral-grey: #6f7779;
  --neutral-white: #ffffff;
  --txt-dark-grey: #666;
  --txt-grey: #999;

  // Secondary colors
  --yellow: #ffcc33;
  --dark-yellow: #946f00;
  --lima: #abc111;
  --neutral-green: #63ba68;
  --available-neutral-green: #008437;
  --verde-oscuro: #008439;
  --turquesa: #1bb3bc;
  --turquesa-oscuro: #137e84;
  --rosa: #ce9ab3;
  --purpura: #9e3667;
  --purpura-oscuro: #852145;
  --azul-cielo: #bae8ea;
  --azul: #3366ff;
  --azul-oscuro: #0032e6;
  --grey-placeholder: #cccccc;
  --blue-focus: #9bc3d3;

  // Input traslate colors
  --dark-yellow-14: #f0ebdb;

  // Line colors
  --grey-line: #d9d9d9;
  --soft-grey-line: #e5e5e5;

  // Background colors
  --bg-grey: #f7f7f7;
  --bg-grey30: #deedf2;

  // Grey disables
  --grey-disabled: #999999;

  --input-border-grey: #dbe0e3;
  --input-btm-border-grey: #8cbabd;
  --input-background: #f6f6f6;

  --input-background-form: #F5F9FB;
  --input-border-form: #deedf2;
  --input-line-base-form: #257FA4;

  // Text size
  --font-size-sm: 14px;
  --font-size-fs: 16px;
  --font-size-md: 18px;
  --font-size-lg: 24px;
  --font-size-xl: 30px;
}

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: $spacer,
      4: ($spacer * 1.5),
      5: ($spacer * 3),
      6: ($spacer * 8)
    ),
    $spacers
);

