ngb-datepicker {
  font-family: 'Santander Text', sans-serif;
  background-color: var(--neutral-white);
  .ngb-dp-weekday {
    font-style: normal;
    color: #9bc3d3;
    text-transform: uppercase;
  }
  .btn-light {
    border-radius: 50%;
  }
  .ngb-dp-header,
  .btn-link {
    color: car(--primary-red);
  }
  .bg-light {
    background-color: var(--neutral-white);
  }
  .bg-primary {
    background-color: var(--primary-red) !important;
  }
  .custom-day {
    text-align: center;
    padding: .185rem .25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    color: #666666;
    box-sizing: border-box;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range {
    background-color: #1bb3bc;
    color: white;
    margin-left: -.5px;
  }
  .custom-day.weekend {
    color: #eb2b0a;
  }
  .custom-day.outMonth,
  .custom-day.weekend {
    color: var(--soft-grey-line);
  }
  .custom-day.hidden {
    display: none;
  }
  .ngb-dp-month-name {
    font-size: 14px;
  }
  .ngb-dp-arrow-btn > .ngb-dp-navigation-chevron {
    border-width: .1em .1em 0 0;
    color: var(--primary-red);
  }
  .ngb-dp-arrow-btn {
    z-index: 0;
  }
  .custom-day {
    font-size: 14px;
    height: 1.5rem;
    padding: 0 .25rem;
  }
  .custom-day.last {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 1px;
    width: calc(2rem - 1px);
  }
  .custom-day.first {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    margin-left: 1px;
    width: calc(2rem - 1px);
  }
  :focus {
    outline: none;
    outline-color: transparent;
    outline-style: none;
    outline-width: 0;
  }
}
// INPUT CALENDARIO CONTROL HORARIO
.ngb-dp-week {
  .ngb-dp-weekday {
    text-transform: uppercase;
    font-style: normal;
    color: var(--txt-grey);
    font-weight: 600;
  }
}

ngb-datepicker-navigation-select > .custom-select {
  color: var(--primary-red);
}

ngb-datepicker-navigation-select > .custom-select:focus {
  border-color: var(--primary-red);
  outline: 0;
  box-shadow: none;
}

ngb-datepicker-navigation-select > select::-ms-expand {
  display: none;
}

ngb-datepicker-month-view {
  .ngb-dp-day {
    height: 1.8rem;
    width: 1.8rem;
    .btn-light {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.8rem;
      width: 1.8rem;
      font-size: 14px;
      color: var(--txt-dark-grey);
    }
  }
}

select::-ms-expand {
  display: none;
}

.ngb-dp-header,
.ngb-dp-months,
.ngb-dp-week.ngb-dp-weekdays {
  background-color: var(--neutral-white) !important;
}
