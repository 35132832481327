/* You can add global styles to this file, and also import other style files */
// VARIABLES
@import '../../theme/_variables.scss';

/* Bootstrap 4 Styles */
@import '~bootstrap/scss/bootstrap';
/* Ng-select */
@import '~@ng-select/ng-select/themes/default.theme.css';

/* FONTS */
@import '../css/fonts/fonts.css';
/* NORMALIZE */
@import '../css/normalize.scss';
/* TEXT */
@import '../css/text.scss';
/* BOX */
@import '../css/box.scss';
/* MESSAGES */
@import '../css/message.scss';
/* BUTTONS */
@import '../css/buttons.scss';
/* FORMS */
@import '../css/forms.scss';
/* FORMS */
@import '../css/forms.scss';
/* TABLES */
@import '../css/tables.scss';
/* DATEPICKER */
@import '../css/datepicker.scss';
/* MODAL */
@import '../css/modal.scss';
/* ANIMATIONS */
@import '../css/animations.scss';

@import '../css/general.scss';

