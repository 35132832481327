h1 {
  font-size: 26px;
  font-weight: bold;
  color: var(--primary-red);
}

h2 {
  font-size: 22px;
  line-height: 1.27;
  color: var(--primary-red);
}

h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.33;
  color: var(--neutral-black);
}

p {
font-size: var(--font-size-fs);
}
.mandatory-field-container {
  position: absolute;
  font-size: 14px;
  color: var(--txt-dark-grey);
  left: 0;
  .t-red {
    color: var(--primary-red);
  }
}

.icon-red-txt {
  display: flex;
  color: var(--primary-red);
  align-items: center;
  cursor: pointer;
  i {
    font-size: 24px;
    padding-right: 5px;
  }
}

.icon-grey-txt {
  display: flex;
  color: grey;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 24px;
    padding-right: 5px;
  }
}



.add-new-item {
  padding: 1rem 0;
  font-weight: normal;
  cursor: pointer;
  width: 25%;
  color: var(--primary-red);
  .icn-plus {
    margin-right: .5rem;
    font-size: 30px;
    transition: all .5s ease;
    &:before {
      color: var(--primary-red);
    }
  }

  &>div:hover {
    .icn-plus {
      transform: rotate(180deg);
    }
  }
}

.alert-msg {
  color: var(--primary-red);
  display: flex;
  align-items: center;
  font-size: 12px;
}

// estilo enlaces
a {
  color: var(--primary-red);
  cursor: pointer;
}

a:hover {
  color: var(--secondary-red);
  text-decoration: none;
}

.rotate_45deg {
  transform: rotate(45deg);
  display: inline-block;
  i.icn-cancel-user {
    font-size: 16px;
  }
}
