.boxes-container {
  margin: 0 -10px;
}

.box {
  text-align: center;
  font-weight: bold;
  list-style: none;
  border: 1px solid var(--grey-line);
  padding: 25px 40px;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .1);
  cursor: pointer;
  transition: box-shadow 350ms;
  position: relative;
  overflow: hidden;
  min-height: 144px;
  width: calc(33.3333% - 20px);
  @media only screen and (max-width: 798px) {
    width: calc(100% - 20px);
  }

  &:hover {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2);

    .box-section {
      color: var(--primary-red);
      transition: color 350ms ease-out;
    }
  }

  &-container {
    padding: 0;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  img {
    width: 48px;
    height: 48px;
  }

  &-section {
    font-size: 18px;
    font-weight: bold;
  }

  .section-description {
    color: var(--txt-dark-grey);
    font-weight: normal;
  }

  &-header {
    border-radius: 6px;
    box-shadow: none;
    background: linear-gradient(286deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    // url('/../assets/img/beneficios_nivel1.png') no-repeat center center;
    background-size: 100% auto;
    height: 295px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    // img para integracion
    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: baseline;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-txt {
      font-weight: bold;
      padding: 50px;
      display: flex;
      flex-flow: column;
      color: var(--neutral-white);
      width: 100%;
    }

    &-sub {
      text-transform: uppercase;
      font-size: 14px;
    }

    &-tit {
      font-family: 'Santander Headline', sans-serif;
      font-size: 40px;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.first-level-container {
  .box-info {
    text-align: left;
    padding-top: 6px;
  }
}

.second-level-container {
  .box {
    flex-flow: row nowrap;
    display: flex;
    width: calc(50% - 20px);
    min-height: auto;
  }

  .box-icon {
    padding-right: 2rem;
  }

  .box-info {
    text-align: left;
    padding-top: 0;
  }

  .section-description {
    display: block !important;
    padding-top: 4px;
  }
}

.third-level-container {
  .box {
    padding: 20px;
  }

  .box-icon {
    display: none;
  }

  .box-info {
    text-align: left;
    padding-top: 0;
  }

  .section-description {
    display: block !important;
    padding-top: 4px;
  }
}

// item impar
// .box:first-child:nth-last-child(odd),
// .box:first-child:nth-last-child(odd) ~ .box {
//   width: calc(33.3333% - 20px);
//   @media only screen and (max-width: 798px) {
//     width: calc(100% - 20px);
//   }
// }

// item par
// .box:first-child:nth-last-child(2n),
// .box:first-child:nth-last-child(2n) ~ .box {
//   width: calc(50% - 20px);
//   @media only screen and (max-width: 798px) {
//     width: calc(100% - 20px);
//   }
// }

// item múltiplo de tres
// .box:first-child:nth-last-child(3n),
// .box:first-child:nth-last-child(3n) ~ .box {
//   width: calc(33.3333% - 20px);
//   @media only screen and (max-width: 798px) {
//     width: calc(100% - 20px);
//   }
// }

// un item
// .box:first-child:nth-last-child(1) {
//   width: calc(100% - 20px);
//   @media only screen and (max-width: 798px) {
//     width: calc(100% - 20px);
//   }
// }

.box-form {
  border: 1px solid var(--grey-line);
  padding: 1rem;
  border-radius: 4px;
  // box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .1);
  color: var(--txt-dark-grey);
  margin-bottom: 1rem;

  .box-info {
    i {
      font-size: 24px;
      cursor: pointer;

      &:first-child {
        padding-right: 4px;
      }

      &:last-child {
        padding-left: 4px;
      }

      &:first-child:last-child {
        padding: 0;
      }
    }
  }
}

.logo-img {
  max-width: 7rem;
}

.box-header-title {
  font-size: var(--font-size-lg);
  font-weight: lighter;
}

@media only screen and (min-height: 800px) {
  .box-header-title {
    font-size: var(--font-size-xl);
  }
}

