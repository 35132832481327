.btn {
  border-radius: 24px;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 3rem;
  padding-right: 3rem;

  &:focus {
    box-shadow: none;
  }

  &-primary {
    background-color: var(--primary-red);
    border-color: var(--primary-red);

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &.dropdown-toggle,
    &:focus {
      background-color: var(--secondary-red);
      border-color: var(--primary-red);
    }
  }

  &-secondary {
    background-color: var(--neutral-white);
    border-color: var(--primary-red);
    color: var(--primary-red);

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &.dropdown-toggle,
    &:focus {
      background-color: var(--primary-red);
      color: var(--neutral-white);
      border-color: var(--primary-red);
      outline: none;
    }
  }

  &:disabled,
  &.disabled {
    color: var(--grey-disabled) !important;
    background-color: var(--soft-grey-line) !important;
    border-color: var(--grey-disabled) !important;
  }

  &-add {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}


.btn-sm {
  font-size: 12px;
}

.btn-block {
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-top: 1.5rem;
  position: relative;

  .btn:first-child:not(:last-child) {
    margin-right: 1rem;
  }

  .btn:first-child + .btn:last-child {
    margin-left: 1rem;
  }
}


//PAGINATOR LIBRERÍA COMPONENTE
.ngx-pagination {
  font-weight: normal;
  text-align: center;
}

.ngx-pagination a,
.ngx-pagination button,
.ngx-pagination li > a {
  color: var(--primary-red) !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: transparent !important;
}

.ngx-pagination .current {
  background: transparent !important;
  color: var(--secondary-black) !important;
}

.ngx-pagination li:not(:last-child) {
  border-left: 1px solid #e5e5e5;
}

.ngx-pagination li:nth-last-child(2) {
  border-right: 1px solid #e5e5e5;
}

.ngx-pagination li:first-child {
  border-left: none;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "<" !important;
  font-size: 20px;
  top: 1px;
  position: relative;
  font-weight: normal !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: ">" !important;
  font-size: 20px;
  top: 1px;
  position: relative;
  font-weight: normal !important;
}
