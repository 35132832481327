@font-face {
    font-family: 'Santander Text';
    src: url('SantanderText-Light.woff2') format('woff2'),
        url('SantanderText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Santander Text';
    src: url('SantanderText-Bold.woff2') format('woff2'),
        url('SantanderText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Santander Headline';
    src: url('SantanderHeadline-Bold.woff2') format('woff2'),
        url('SantanderHeadline-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Santander Headline';
    src: url('SantanderHeadline-Regular.woff2') format('woff2'),
        url('SantanderHeadline-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Santander Text';
    src: url('SantanderText-Regular.woff2') format('woff2'),
        url('SantanderText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

