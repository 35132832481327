.modal {
  &-dialog {
    width: 80%;
    max-width: 1024px;
  }
  &-content {
    padding: 2rem 3rem 4rem 3rem;
  }
  &-header {
    padding: 0;
    padding-bottom: 10px;
    color: var(--primary-red);
    border-color: var(--primary-red);
    margin-bottom: 1rem;
    h5 {
      font-size: 22px;
    }
    button.close > span {
      color: var(--primary-red);
      font-size: 32px;
    }
    button.close {
      outline: none;
    }
  }
  &-footer {
    justify-content: center;
    border: none;
  }
  &-body {
    font-size: 18px;;
    line-height: 1.33;
    color: var(--neutral-black);
    text-align: center;
    padding: 2rem 0;
    .form-group {
      text-align: left;
    }
  }
}
