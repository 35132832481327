.table {
  thead {
    th {
      font-size: 13px;
      text-transform: uppercase;
      vertical-align: middle;
      padding-top: .2rem;
      padding-bottom: .2rem;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
    }
  }

  tbody {
    th {
      color: var(--txt-dark-grey);
      line-height: 1.07;
    }

    td {
      font-size: 15px;
      color: var(--txt-dark-grey);
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 0rem;
      vertical-align: middle;
      line-height: 1.33;
      border-top: none;

      i {
        font-size: 24px;
        cursor: pointer;

        &:before {
          color: var(--primary-red);
        }
      }

      .icn-view {
        cursor: pointer;
      }

      .red-round-check {
        left: -10px;
      }
    }

    tr {
      border-top: 1px solid #dee2e6;
      &:last-child {
        border-bottom: 1px solid #dee2e6;
      }
      th {
        background-color: greenyellow;
      }
      &.add-item-row{
        td {
          border-top: none;
        }
      }
    }
  }

  .bgGrey {
    background-color: #fafafa;

    i.icn-dropdown {
      transform: rotate(180deg);
    }
  }

  .detail {
    td {
      padding: 1.5rem 1rem;
    }
  }

  .status {

    .status-ko,
    .status-ok {
      display: flex;
      align-items: center;

      i {
        padding-right: 5px;
        font-size: 32px;
      }
    }

    .status-ko {
      color: var(--primary-red);
    }

    .status-ok,
    .icn-ok:before {
      color: var(--neutral-green);
    }
  }

  .arrow-controls {
    display: flex;
    flex-flow: column;
    padding-left: 3px;
    i {
      cursor: pointer;
      font-size: 12px;
    }
  }

  td.xs-padding {
    padding: .3rem;
  }

  td.xs-padding+td {
    padding-left: .3rem;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--neutral-white);
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--bg-grey);
}

//TABLE SCROLL
table.table-scroll {
  table-layout: fixed;
  width: 100%;

  td {
    width: 180px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    &.empty-column-action {
      width: 80px;
    }
  }

  th {
    position: absolute;
    left: 0;
    width: 80px;
    z-index: 1;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: -.5px;
    }
  }

  tr.thead {
    th {
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      margin-top: -1px;
      //ALtura mínima para que se de haga el scroll horizontal correctamente
      min-height: 150px;
      background-color: white;
      padding-top: 0.2rem !important;
      // border-right: 1px solid #dee2e6!important;
    }
    td {
      font-size: 13px;
      text-transform: uppercase;
      vertical-align: middle;
      padding-top: .2rem;
      padding-bottom: .2rem;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      font-weight: bold;
      &:first-child {
        .action-item {
          display: flex;
          margin-left: 48px;
        }
      }
    }
  }

  .tbody {
    min-height: 32px;
    th {
      border-top: 1px solid #dee2e6 !important;
      margin-top: -1px;
      //ALtura mínima para que se de haga el scroll horizontal correctamente
      min-height: 150px;
      // border-right: 1px solid #dee2e6!important;
      z-index: 2;
      i {
          font-size: 24px;
          cursor: pointer;
          &:before {
            color: var(--primary-red);
          }
        }

        .action-item {
          &:first-child {
            padding-right: 6px;
          }
          &:last-child {
            padding-left: 6px;
          }
          &:first-child:last-child {
            padding: 0;
          }
        }
    }

    td {
      min-height: 32px;

      font-size: 15px;
      color: var(--txt-dark-grey);
      padding-top: .5rem;
      padding-bottom: .5rem;
      vertical-align: middle;
      line-height: 1.07;
      padding-right: 30px;
    }

    &:nth-of-type(odd) {
      background-color: var(--neutral-white);
      th {
        background-color: var(--neutral-white);
      }
    }
    &:nth-of-type(even) {
      background-color: var(--bg-grey);
      th {
        background-color: var(--bg-grey);
      }
    }
  }
  // tr:nth-last-child(2){
  //   th {
  //     min-height: 135px;
  //   }
  // }

  // tr:last-child {
  //   th {
  //     min-height: 75px;
  //   }
  // }
}

  .outer-table-scroll {
    position: relative;
    &.column-fixed-small {
      .tbody > th {
        width: 45px;
      }
      .inner-table-scroll {
        margin-left: 45px;
      }
    }
  }

  .inner-table-scroll {
    overflow-x: scroll;
    overflow-y: visible;
    // margin-left: 80px;
    padding-bottom: 15px;
    min-height: 250px;
    .table th, .table td {
      border: none;
    }
    tr {
      border-top: 1px solid #dee2e6;
    }

    &.scroll-styled {
      &::-webkit-scrollbar {
        width: 2px;
        height: 6px;
      }

      &::-webkit-scrollbar-button:increment {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-red);
        border-radius: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        margin-right: 80px;
      }
  }
}

  .actionColumn {
      td:first-child {
          width: 100px;
      }
  }

//Pinta las filas impares en blanco para evitar error de overflow gris en la última fila impar
table.table-striped.table-odd {
  .tbody:nth-of-type(odd) {
    background-color: var(--bg-grey);
    th {
      background-color: var(--bg-grey);
    }
  }
  .tbody:nth-of-type(even) {
    background-color: var(--neutral-white);
    th {
      background-color: var(--neutral-white);
    }
  }
}
